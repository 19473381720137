.nav-tabs > li {
  font-weight: bold;
  font-size: 16px; }

.nav-justified > .active > a {
  border: 2px solid #ddd !important;
  border-bottom: none !important; }

.nav-justified > li > a {
  border-bottom: 2px solid #ddd !important; }
